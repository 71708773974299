import React from 'react';
import Layout from '../../components/Layout';
import logo from '../../assets/images/logo.png';
import pic1 from '../../assets/images/bg_crb.jpg';
import pic2 from '../../assets/images/crb_logo1.png';
import config from '../../../config';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>
            Colorado River Boys
          </h2>
          <h2>
          <a
              href="https://www.instagram.com/coloradoriverboys/"
              className="icon fa-instagram"
            >
              &nbsp;
            </a>
            <a
              href="https://twitter.com/coriverboys"
              className="icon fa-twitter"
            >
              &nbsp;
            </a>
            <a
              href="https://www.facebook.com/coloradoriverboys"
              className="icon fa-facebook"
            >
              &nbsp;
            </a>
            <a
              href="https://www.youtube.com/channel/UCxWq4s14Pz3vur1TRC9o47A"
              className="icon fa-youtube"
            >
              &nbsp;
            </a>
            <a
              href="https://coloradoriverboys.com"
              className="icon fa-external-link-square"
            >
              &nbsp;
            </a>
            </h2>

            <h3>Texas Country outta Volente, TX</h3>
        </div>
      </header>
      <div className="wrapper">
        <div className="inner">
          <div id="curator-feed-coriverboys-layout">
            <a href="" target="_blank" className="crt-logo crt-tag">
              .
            </a>
          </div>
          <section>
            <h3 className="major">The Colorado River Boys Discography</h3>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Release</th>
                    <th>Title</th>
                    <th>Where to Listen</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2021</td>
                    <td>
                      <b>The Colorado River Boys Live Album - 2021.06.12 - Twin Creeks Icehouse, Volente, TX</b>
                    </td>
                    <td>
                      <ul className="icons icons-header">
                        <li>
                          <a href="https://soundcloud.com/sessionswest/sets/2021-06-12-crb-twin-creeks" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="2"></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </section>
          <section className="artist">
            <h3 className="major">Bio</h3>
            <p>
              Ryan Tuttle is a vocalist that has been singing and performing
              from a young age. Born and raised in Austin, Ryan grew up
              surrounded by country music and learned to play guitar in San
              Marcos in the late 90’s. Being the frontman for CRB is fulfilling
              a life-long dream to play the music he loves. Ryan lives in Cedar
              Park with his beautiful wife and 2 children. Ryan is also active
              in the children’s music ministry of Hill Country Bible Church and
              can be found most Sunday mornings playing and singing worship
              songs with the kids.{' '}
            </p>
            <span className="image right">
              <img src={pic2} alt="" />
            </span>
            <p>
              Corey Butler is an American musician singer-song writer from Baton
              Rouge, LA who has served as a sideman in several touring bands.
              Best known as the guitar player for touring indie rock bands from
              Austin, TX such as nogood boyo and the Normans. While on tour in
              the 90's Corey opened for band such as White Zombie and Revernd
              Horton Heat, and played at iconic venues like CBGBs in NYC.
            </p>
            <p>
              Jesse Cravens is a bass player and singer songwriter based out of
              Austin, TX. In the late 1990's, Jesse spent three years touring
              the continental US, playing over 200 shows a year, and learning
              the ropes as a frontman, songwriter, bass player. During that
              time, Jesse opened for Colonel Bruce Hampton and the Codetalkers,
              JJ Grey and Mofro, Vasser Clements and Northern Lights, Ekoostic
              Hookah, Perpetual Groove, The Recipe, Acoustic Syndicate, to name a 
              few and played at iconic venues such as the Cleveland's Agora Theater, 
              the Newport Music Hall in Columbus, Colorado's Rico Theater during the 
              2001 Telluride Blugrass Festival, a late night show at the 2002 New Orleans Jazz
              Festival, the Pourhouse in Raleigh, NC and Austin's own Flamingo
              Cantina and Momo's. Later, betwen 2007-2009, Jesse joined the
              Pittsburgh-based band Peacefield as their bass player to help build
              their new Texas sound, and recorded the bass tracks on 'Stars
              Away' which was produced by Frogville Records producer Bill Palmer
              of Austin's Sharecroppers and Hundred Year Flood fame. </p>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);
export default IndexPage;